import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col, Dropdown, Form, Button, Spinner, DropdownButton } from "react-bootstrap";
import styled from "styled-components";
import CanadaPostAutoCompleteAddress from "../components/CanadaPostAutoCompleteAddress";
import { Trans } from "react-i18next";
import { goTo, numbersOnly } from "../utilities/utils";
import { api } from "../utilities/api";
import Modal from "../components/Modal";

export default function finalizeYourTermination() {
  const [clientName, setClientName] = useState("");
  const [sid, setSID] = useState("");
  const [cnum, setCnum] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [financialInstitutions, setFinancialInstitutions] = useState([]);
  const [accountNumberMax, setAccountNumberMax] = useState(7);
  const [bankOrCreditCard, setBankOrCreditCard] = useState("Credit Card");
  const DEFAULT_BANK_TEXT = "Choose Your Bank"
  const [bankName, setBankName] = useState(DEFAULT_BANK_TEXT);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [transitNumber, setTransitNumber] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCVV] = useState("");
  const bankSelected = bankOrCreditCard === "Canadian Bank";
  const cardSelected = bankOrCreditCard === "Credit Card";
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refundSuccess, setRefundSuccess] = useState(false);
  const [refundFailure, setRefundFailure] = useState(false);
  const [alreadySet, setAlreadySet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleSIDCheck = () =>{
    let sid = new URLSearchParams(window.location.search).get("sid");

    const getRefundClient = async () => {
      const getRefundClientResponse = await api.getRefundClient(sid);

      if (!getRefundClientResponse || !getRefundClientResponse.data) {
        console.log("Something went wrong with getRefundClient()");
      }

      if (getRefundClientResponse && getRefundClientResponse.data) {
        if (getRefundClientResponse.data.error && getRefundClientResponse.data.error === "invalid sid") {
          goTo("/", {}, true);
        }
        if (getRefundClientResponse.data.error && getRefundClientResponse.data.error.includes("Fatal")) {
          goTo("/", {}, true);
        }
        if (getRefundClientResponse.data.error && getRefundClientResponse.data.error === "already set") {
          setShowModal(false);
          setAlreadySet(false);
        }
        setClientName(getRefundClientResponse.data.cname);
        setCnum(getRefundClientResponse.data.cnum);
        setPhoneNumber(getRefundClientResponse.data.pnum);
      }
    }

    if (sid) {
      setSID(sid);
      getRefundClient().catch(err => console.error(err));
    } else {
      goTo("/", {}, true);
    }
  }

  useEffect(() => {
    handleSIDCheck();

    api.getRefundFinancialInstitutions()
      .then(res => {
        if (res.data) {
          setFinancialInstitutions(res.data);
        }
      })
      .catch(err => {
        console.error(err);
      })
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [isMobile]);

  const onClickSubmit = () => {
    if (cardSelected) {
      validateCardInfoAndPost();
    }

    if (bankSelected) {
      validateBankInfoAndPost();
    }
  }

  const validateBankInfoAndPost = () => {
    if (bankName === DEFAULT_BANK_TEXT) {
      setError("Please select a bank.");
      return;
    }

    if (!accountNumber) {
      setError("Account Number is required");
      return;
    }

    if (!accountHolder) {
      setError("Account Holder is required.");
      return;
    }

    if (!transitNumber) {
      setError("Transit Number is required.");
      return;
    }

    if (!billingAddress) {
      setError("Billing Address is required.");
      return;
    }

    if (!bankAddress) {
      setError("Bank Address is required.");
      return;
    }

    setError("");

    let postRefundBankRequest = {
      sid: sid,
      iname: bankName,
      acnum: accountNumber,
      acholder: accountHolder,
      transit: transitNumber,
      bankaddr: bankAddress,
      billaddr: billingAddress
    }

    setShowModal(true);
    setLoading(true);
    api.postRefundBank(postRefundBankRequest)
      .then(res => {
        console.log("response: ", res);
        if (res.data === "") {
          setRefundSuccess(true);
        } else {
          setRefundFailure(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }

  const canPay = () => {
    if (cardNumber.length < 15 || cvv.length < 3 || !cardholderName || expirationDate.length < 4) {
      return false;
    }
    return true;
  }

  const validateCardInfoAndPost = () => {
    if (!canPay()) {
      setError("Please fill out all of the required fields.");
      return;
    }

    setError("");

    let month = expirationDate.split("/")[0];
    let year = "20" + expirationDate.split("/")[1];
    
    let postRefundCardRequest = {
      sid: sid,
      crnum: cardNumber,
      crholder: cardholderName,
      crmonth: month,
      cryear: year,
      ccv: cvv
    }

    setShowModal(true);
    setLoading(true);

    api.postRefundCard(postRefundCardRequest)
      .then(res => {
        console.log("response: ", res);
        if (res.data === "") {
          setRefundSuccess(true);
        } else {
          setRefundFailure(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }

  const handleExpirationDate = (e) => {
    const value = numbersOnly(e.target.value);
    if (value.length > 2) {
      const expMonth = value.substring(0, 2);
      const expYear = value.substring(2);
      setExpirationDate(`${expMonth}/${expYear}`);
      return;
    }
    setExpirationDate(value.replace('/', ''))
  };

  const handleBankRadioCheck = (event) => {
    setBankOrCreditCard(event.target.value);
  }

  const getBankAddressAutoComplete = (full_address) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    setBankAddress(fullAddress);
  };

  const getBillingAddressAutoComplete = (full_address) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    setBillingAddress(fullAddress);
  };

  const onClickOK = () => {
    setShowModal(false);
  }

  const onClickReturn = () => {
    goTo("/", {}, true);
  }

  const onClickMail = () => {
    window.location.href = "mailto:services@gophonebox.com"
  }

  const onClickPhone = () => {
    window.location.href = "callto:1-855-886-0505"
  }

  const handleModalClose = () => {
    if (loading) {
      return;
    }
    if (refundSuccess || alreadySet) {
      goTo("/", {}, true);
    }
    setShowModal(false);
  }

  return (
    <PageLayout>
      <title>Finalize Your Termination | PhoneBox</title>
      <Wrapper>
        <Container>
          <BackgroundDiv style={{ maxWidth: "900px", margin: "auto" }}>
            <BlueTitle className="p-2">
              Termination Refund
            </BlueTitle>
            <div>
              <h4 className="p-2">
                Hello {clientName},
              </h4>
              <div className="p-2">
                You have almost completed your Termination. Please choose and fill out your Refund Method to receive your Refund. Your refund will be calculated and refunded to whatever bank or credit card you set below.
              </div>
              <div className="p-2">
                {isMobile ? (
                  <table style={{ width: "100%" }}>
                    <tr>
                      <BorderedSquare>Customer #</BorderedSquare>
                      <BorderedSquare style={{ fontWeight: 500 }}>{cnum}</BorderedSquare>
                    </tr>
                    <tr>
                      <BorderedSquare>Phone #</BorderedSquare>
                      <BorderedSquare style={{ fontWeight: 500 }}>{phoneNumber}</BorderedSquare>
                    </tr>
                  </table>
                ) : (
                  <table style={{ width: "100%" }}>
                    <tr>
                      <BorderedSquare>Customer #</BorderedSquare>
                      <BorderedSquare style={{ fontWeight: 500 }}>{cnum}</BorderedSquare>
                      <BorderedSquare>Phone #</BorderedSquare>
                      <BorderedSquare style={{ fontWeight: 500 }}>{phoneNumber}</BorderedSquare>
                    </tr>
                  </table>
                )}
              </div>
              <div className="p-2">
                <RequiredLabel>
                  How would you like to receive your Deposit Return?
                </RequiredLabel>
                <div className="d-flex flex-row align-items-center">
                  <Radio type="radio" onClick={handleBankRadioCheck} value="Credit Card" checked={cardSelected}/>
                  <Text className="mx-2">
                    <Trans>Credit Card</Trans>
                  </Text>
                  <Radio type="radio" style={{ marginLeft: "50px" }} onClick={handleBankRadioCheck} value="Canadian Bank" checked={bankSelected}/>
                  <Text className="mx-2">
                    <Trans>Canadian Bank</Trans>
                  </Text>
                </div>
              </div>
              <div className="p-2">
                <StyledHR/>
              </div>
              {cardSelected &&
                <>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>Card Number</RequiredLabel>
                        <Form.Control value={cardNumber} placeholder="1234567890123456" onChange={(event) => setCardNumber(numbersOnly(event.target.value))} maxLength={20}></Form.Control>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>Cardholder Name</RequiredLabel>
                        <Form.Control value={cardholderName} onChange={(event) => setCardholderName(event.target.value)} placeholder="Your Name"></Form.Control>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>Expiration Date</RequiredLabel>
                        <Form.Control placeholder="MM/YY" value={expirationDate} onChange={(event) => handleExpirationDate(event)} maxLength={5}></Form.Control>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>CVV</RequiredLabel>
                        <Form.Control 
                          type="text" 
                          value={cvv} 
                          maxLength={4}
                          placeholder="123"
                          onChange={(event) => setCVV(numbersOnly(event.target.value))} 
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              }
              {bankSelected &&
                <>
                  <div className="p-2">
                    For more information on participating banks and other related information please visit our <ClickableLink onClick={() => goTo("/support", {}, true)}>Support Page</ClickableLink>.
                  </div>
                  <div className="p-2">
                    <RequiredLabel>Bank Name</RequiredLabel>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary">
                        {bankName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ zIndex: 2 }}>
                        {financialInstitutions.map((fi, index) => (
                          <Dropdown.Item key={index} onClick={() => { 
                            setBankName(fi.iname);
                            setAccountNumberMax(fi.acnummax);
                          }}>
                            <Trans>{fi.iname}</Trans>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="p-2">
                    <RequiredLabel>Account Number <ItalicText>{`(${accountNumberMax} digits)`}</ItalicText></RequiredLabel>
                    <Form.Control value={accountNumber} maxLength={accountNumberMax} onChange={(event) => setAccountNumber(numbersOnly(event.target.value))}></Form.Control>
                  </div>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>Account Holder</RequiredLabel>
                        <Form.Control type="text" value={accountHolder} onChange={(event) => setAccountHolder(event.target.value)} placeholder="Your Name"></Form.Control>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="p-2">
                        <RequiredLabel>Transit Number <ItalicText>(5 digits)</ItalicText></RequiredLabel>
                        <Form.Control type="text" value={transitNumber} maxLength={5} onChange={(event) => setTransitNumber(numbersOnly(event.target.value))}></Form.Control>
                      </div>
                    </Col>
                  </Row>
                  <div className="p-2">
                    <RequiredLabel>Billing Address</RequiredLabel>
                    <CanadaPostAutoCompleteAddress selectedCountry={'CA'} onAutoCompleteAddress={getBillingAddressAutoComplete} placeholder="Enter your billing address"/>
                  </div>
                  <div className="p-2">
                    <RequiredLabel>Bank Address</RequiredLabel>
                    <CanadaPostAutoCompleteAddress selectedCountry={'CA'} onAutoCompleteAddress={getBankAddressAutoComplete} placeholder="Enter your bank's address"/>
                  </div>
                </>
              }
              {error.length > 0 &&
                <CenteredDiv style={{ marginTop: "16px" }}>
                  <p style={{ color: "red" }}>{error}</p>
                </CenteredDiv>
              }
              <CenteredDiv style={{ marginTop: "16px" }}>
                <CustomButton onClick={onClickSubmit}>Submit</CustomButton>
              </CenteredDiv>
            </div>
          </BackgroundDiv>
        </Container>
      </Wrapper>
      {showModal && 
        <Modal 
          onClose={handleModalClose}
          noCloseButton
          maxWidth={loading ? "400px" : "650px"}
          body={
            <div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>
              {loading &&
                <>
                  <div className="d-flex align-items-center pt-3">
                    <Spinner variant="primary" className="m-auto" animation="border" />
                  </div>
                  <br/>
                  <div className="text-center py-2">
                      <small>Processing your refund...</small>
                  </div>
                </>
              }
              {!loading &&
                <>
                  {alreadySet &&
                    <>
                      <TextCenter>
                        <p>You have already sent in your refund request.</p>
                        <p>If you have any problems with your refund please email us at <ClickableLink onClick={onClickMail}>services@gophonebox.com</ClickableLink> or call at <ClickableLink onClick={onClickPhone}>1-855-886-0505</ClickableLink>.</p>
                        <p>Click "Return" to be directed to the home page.</p>
                      </TextCenter>
                      <CenteredDiv style={{ marginTop: "12px" }}>
                        <Button variant="primary" onClick={onClickReturn}>
                          Return
                        </Button>
                      </CenteredDiv>
                    </>
                  }
                  {refundSuccess &&
                    <>
                      <TextCenter>
                        <BlueTitle style={{ padding: "16px" }}>Thank You!</BlueTitle>
                        <p>We thank you for using PhoneBox and hope to see you again soon.</p>
                        <p>If you have any problems with your refund please email us at <ClickableLink onClick={onClickMail}>services@gophonebox.com</ClickableLink> or call at <ClickableLink onClick={onClickPhone}>1-855-886-0505</ClickableLink>.</p>
                        <p>Click "Return" to be directed to the home page.</p>
                      </TextCenter>
                      <CenteredDiv style={{ marginTop: "12px" }}>
                        <Button variant="primary" onClick={onClickReturn}>
                          Return
                        </Button>
                      </CenteredDiv>
                    </>
                  }
                  {refundFailure &&
                    <>
                      <TextCenter>
                        <p>Sorry, something went wrong with processing your refund.</p>                    
                        <p>Click "OK" to close this message and try again.</p>                    
                      </TextCenter>
                      <CenteredDiv style={{ marginTop: "12px" }}>
                        <Button variant="primary" onClick={onClickOK}>
                          OK
                        </Button>
                      </CenteredDiv>
                    </>
                  }
                </>
              }
            </div>
          }
        />
      }
    </PageLayout>
  )
}

const BlueTitle = styled.h3`
  font-weight: 600;
  color: #0494CA;
  font-size: 30px;
`

const Wrapper = styled.div`
  padding-top: 40px;
`;

const CenteredDiv = styled.div`
  width: fit-content;
  margin: auto;
`

const BackgroundDiv = styled.div`
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 10px;
`

const Label = styled.div<{ topGap?: string; bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: ${(props) => props.topGap};
  margin-bottom: ${(props) => props.bottomGap};
  width: max-content;
  span {
    color: red;
  }

  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const CustomButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px auto;
  width: fit-content;
  :hover {
    color: #fff;
  }
`;

const RequiredLabel = styled.p<{ digits?: number }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  :after {
    color: #e32;
    content: " *";
    display: inline;
  }

  @media (max-width: 418px) {
    font-size: 14px;
  }
`;

const ItalicText = styled.i`
  color: #555555;
  font-weight: 400;
`

const Radio = styled.input`
  border: 0px;
  width: 18px;
  height: 18px;
  :hover {
    cursor: pointer;
  }
`;

const ClickableLink = styled.span`
  color: #0494CA;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const BorderedSquare = styled.td`
  border: 1px solid #bababa;
  padding: 4px;
`

const Text = styled.div`
  font-size: 16px;
  color: black;
  font-weight: 500;
  width: max-content;
  span {
    color: red;
  }
  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const TextCenter = styled.div`
  text-align: center;
`

const StyledHR = styled.hr`
  width: 50%;
  color: #212529;
  border-top: 1px solid;
  opacity: .25;
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;